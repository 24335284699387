import React, {useEffect, useState} from 'react'
import {Row, Col, Spinner, Dropdown, Container} from "react-bootstrap"
import InstrumentDatum from "../resources/InstrumentDatum"
import styles from "../styles/InstrumentDataModal.module.css"
import InstrumentDataChart from "./InstrumentDataChart"
import moment from "moment-timezone"
import DateTime from "react-datetime"

export default ({instrument}) => {
  const [ data, setData ] = useState([])
  const [ requestedChartMode, setRequestedChartMode ] = useState({span: 24, resolution: 'hour'})
  const [ chartMode, setChartMode ] = useState({span: 24, resolution: 'hour'})
  const [ timeSpan, setTimeSpan ] = useState('day')
  const [ loading, setLoading ] = useState(false)
  const [ date, setDate ] = useState(moment().endOf('day'))
  const [ fromTime, setFromTime ] = useState(moment().startOf('day'))
  const [ toTime, setToTime ] = useState(moment().endOf('day'))

  useEffect(
    ()=>{
      const instrumentData = new InstrumentDatum(instrument)
      setLoading(true)
      if (requestedChartMode){
        instrumentData.dataForChart(requestedChartMode, date).then(d => {
          setLoading(false)
          setData(d)
          setChartMode(Object.assign({}, requestedChartMode))
        })
      } else if (fromTime && toTime){
        const query = `filter[from]=${fromTime.toISOString()}&filter[to]=${toTime.toISOString()}&page[size]=1500`
        instrumentData.fetchData(query).then(d => {
          setLoading(false)
          setData(d)
          setChartMode(resolveSpanAndResolution(fromTime, toTime))
        })
      }
    },
    [instrument, requestedChartMode, date, fromTime, toTime]
  )

  const resolveSpanAndResolution = (from, to) => {
    let span = Math.ceil(to.diff(from, 'hours')) + 1
    let resolution = 'hour'
    if (span > 24 * 3){
      span = Math.ceil(to.diff(from, 'days')) + 1
      resolution = 'day'
    }
    return {span, resolution}
  }

  const handleDateChange = (momentTime) => {
    let resolvedValue = ''
    if (typeof momentTime === "object"){
      resolvedValue = momentTime.endOf('day')
    }
    setDate(resolvedValue)
  }

  const handleFromTimeChange = (momentTime) => {
    let resolvedValue = ''
    if (typeof momentTime === "object"){
      resolvedValue = momentTime
      if (resolvedValue.valueOf() > toTime.valueOf())
        resolvedValue = toTime
    }
    setFromTime(resolvedValue)
  }

  const handleToTimeChange = (momentTime) => {
    let resolvedValue = ''
    if (typeof momentTime === "object"){
      resolvedValue = momentTime
      if (resolvedValue.valueOf() < fromTime.valueOf())
        resolvedValue = fromTime
    }
    setToTime(resolvedValue)
  }

  const handleTimeSpanSelect = (mode) => {
    switch (mode){
      case 'day':
        setRequestedChartMode({resolution: 'hour', span: 24})
        break
      case 'twoDays':
        setRequestedChartMode({resolution: 'hour', span: 48})
        break
      case 'fiveDays':
        setRequestedChartMode({resolution: 'day', span: 5})
        break
      case 'tenDays':
        setRequestedChartMode({resolution: 'day', span: 10})
        break
      //case 'thirtyDays':
      //  setRequestedChartMode({resolution: 'day', span: 30})
      //  break
      case 'custom':
        setRequestedChartMode(null)
        break
      default:
        console.error("Unknown time span mode: " + mode)
    }
    setTimeSpan(mode)
  }

  const timeSpans = {
    day: '24 hours',
    twoDays: '2 days',
    fiveDays: '5 days',
    tenDays: '10 days',
    //sevenDays: '7 days (3h avgs)',
    //thirtyDays: '30 days (24h avgs)',
    custom: 'Custom'
  }

  const chartUnitPostfix = () => {
    if (timeSpan === 'sevenDays')
      return ' (3h avg)'
    else if (timeSpan === 'thirtyDays')
      return ' (24h avg)'
    else
      return ''
  }

  const TimeFilter = () => (
    <Container fluid>
      <Row noGutters>
        <Col xs="auto" className={[styles.timeFilterItem, "hide-from-print"].join(' ')}>
          <Dropdown>
            <Dropdown.Toggle size="sm" variant="dark" id="dropdown-basic" className={styles.timeSpanDropdownToggle}>
              {timeSpans[timeSpan]}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {Object.keys(timeSpans).map(span => (
                <Dropdown.Item key={span}
                               disabled={false}
                               onClick={() => handleTimeSpanSelect(span)}>
                  {timeSpans[span]}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col xs="auto" className={styles.timeFilterItem}>
          {timeSpan !== 'custom' &&
          <DateTime name="date"
                    inputProps={{readOnly: true}}
                    className={styles.datePicker}
                    onChange={handleDateChange}
                    value={date || ''}
                    viewDate={date}
                    timeFormat={false}
                    dateFormat="MMM Do YYYY"/>}
          {timeSpan === 'custom' &&
          <React.Fragment>
            <DateTime name="fromTime"
                      inputProps={{readOnly: true}}
                      className={[styles.datePicker, styles.datePickerWithTime].join(' ')}
                      onChange={handleFromTimeChange}
                      value={fromTime || ''}
                      viewDate={fromTime}
                      closeOnSelect={false}
                      timeFormat="HH:mm"
                      dateFormat="MMM Do YYYY"/>
            <span>&nbsp;-&nbsp;</span>
            <DateTime name="toTime"
                      inputProps={{readOnly: true}}
                      className={[styles.datePicker, styles.datePickerWithTime].join(' ')}
                      onChange={handleToTimeChange}
                      value={toTime || ''}
                      viewDate={toTime}
                      closeOnSelect={false}
                      timeFormat="HH:mm"
                      dateFormat="MMM Do YYYY"/>
          </React.Fragment>}
        </Col>
        <Col xs="auto" className={styles.timeFilterItem}>
          {loading &&
          <Spinner size="sm" animation="border" role="status" className={styles.spinner}>
            <span className="sr-only">Loading...</span>
          </Spinner>}
        </Col>
      </Row>
    </Container>
  )

  return(
    <React.Fragment>
      {TimeFilter()}
      {data &&
      <InstrumentDataChart data={data}
                           date={timeSpan === 'custom' ? toTime : date}
                           unitPostfix={chartUnitPostfix()}
                           height={300}
                           instrumentType={instrument && instrument.instrumentType}
                           span={chartMode.span}
                           resolution={chartMode.resolution}/>}
    </React.Fragment>
  )
}