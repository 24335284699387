import React, { useEffect } from 'react'
import { Button, Modal } from "react-bootstrap"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faTimes} from "@fortawesome/free-solid-svg-icons"
import InstrumentDataDetails from "./InstrumentDataDetails"

export default ({show, onClose, instrument}) => {
  useEffect(
    () => {
      /*
      if (instrument)
        loadInstrument(instrument)
      else
        setCurrentInstrument(null)*/
    },
    [instrument]
  )

  return(
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        {instrument && instrument.name}
      </Modal.Header>
      <Modal.Body>
        {instrument && <InstrumentDataDetails instrument={instrument}/>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes}/>&nbsp;Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}