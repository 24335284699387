import React from 'react'
import './styles/modal.css'
import './styles/bootstrap.css'
import SensorMap from './components/SensorMap'
import ThemeContextProvider from './contexts/ThemeContext'
import Footer from "./components/Footer"
import Header from "./components/Header"

function App() {
  return (
    <ThemeContextProvider>
      <div className="app">
        <Header/>
        <SensorMap/>
        <Footer/>
      </div>
    </ThemeContextProvider>
  )
}

export default App
