import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

const formats = {
  default: {
    days: 'MMM Do YYYY',
    minutes: 'MMM Do YYYY, HH:mm',
    seconds: 'MMM Do YYYY, HH:mm:ss',
    milliseconds: 'MMM Do YYYY, HH:mm:ss.SSS'
  },
  time: {
    minutes: 'HH:mm',
    seconds: 'HH:mm:ss',
    milliseconds: 'HH:mm:ss.SSS'
  },
  monthAndDay: {
    seconds: 'MMM Do'
  }
}

class Time extends React.Component{
  render(){
    return (
      <span className={"time-component " + (this.props.className ? this.props.className : '')}>
        {Time.formatTime(this.props.children, this.props.format, this.props.resolution)}
      </span>
    )
  }

  static _format(format, resolution){
    if (format === 'withTz')
      return formats['default'][resolution] + ' Z z'
    else
      return formats[format][resolution]
  }

  static parseUTC(jsonTime){
    //console.log(k);

    let date = jsonTime.split('T')[0].split('-')
    let time = jsonTime.split('T')[1].replace('Z','').split(':')

    //console.log(date);
    //console.log(time);

    //let ts = Date.parse(k);

    const year = parseInt(date[0], 10)
    const month = parseInt(date[1], 10) - 1
    const day = parseInt(date[2], 10)
    const hour = parseInt(time[0], 10)
    const minute = parseInt(time[1], 10)
    const second = parseInt(time[2].split('.')[0], 10)
    const milliSecond = parseInt(time[2].split('.')[1], 10)

    return Date.UTC(year, month, day, hour, minute, second, milliSecond)
  }

  static timezone(){
    return moment.tz.guess() || 'Europe/Helsinki'
  }

  static formatTime(time, format = 'default', resolution = 'seconds'){
    if (!time)
      return ''
    return moment(time).tz(Time.timezone()).format(Time._format(format, resolution))
  }

  static durationToNow(fromTime){
    return this.formatDuration(moment().diff(fromTime))
  }

  static formatDuration(ms){
    if (ms === null || isNaN(ms))
      return ''
    if (ms <= 1000)
      return Math.round(ms) + ' ms'
    else if (ms <= 1000 * 60)
      return (Math.round((ms / 1000) * 10) / 10) + ' sec'
    else if (ms <= 1000 * 60 * 60){
      const min = Math.floor(ms / 60000)
      const sec = Math.round(ms % 60000 / 1000)
      if (sec > 0)
        return  `${min} min ${sec} sec`
      else
        return  `${min} min`
    } else {
      const hour = Math.floor(ms / 3600000)
      const min = Math.floor((ms / 60000) % 60)
      //const sec = Math.round(ms % 60000 / 1000)
      return  `${hour} h ${min} min`
    }
  }
}

Time.Zone = () => {
  return <span className="time-component timezone">tz:&nbsp;{Time.timezone()}</span>
}

Time.Duration = (props) => {
  return <span className="time-component duration">{Time.formatDuration(props.children)}</span>
}

Time.DurationToNow = (props) => {
  return <span className="time-component duration">{Time.durationToNow(props.children)}</span>
}

Time.propTypes = {
  time: PropTypes.any,
  format: PropTypes.string,
  resolution: PropTypes.string
}

export default Time