import Time from '../lib/Time'
import mapIconTempSensor from "../images/mapIconTempSensor.png"
import mapIconSoundLevelSensor from "../images/mapIconSoundLevelSensor.png"

const jsonConfig = {
  method: 'GET',
  headers: {
    'Accept': 'application/vnd.api+json',
    'Content-Type': 'application/vnd.api+json'
  }
}

class Instrument{
  constructor(data){
    this.id = data.id
    this.name = data.attributes.name
    this.type = data.attributes.instrument_type
    this.data = []
  }

  icon(){
    switch(this.type) {
      case 'temp_sensor':
        return mapIconTempSensor
      case 'sound_level_sensor':
        return mapIconSoundLevelSensor
      default:
        return null
    }
  }

  color(){
    switch(this.type) {
      case 'temp_sensor':
        return "#fbb"
      case 'sound_level_sensor':
        return "#ddd"
      default:
        return null
    }
  }

  primaryData(){
    if (!this.latestData() || !this.latestData().values)
      return null
    switch(this.type) {
      case 'temp_sensor':
        return this.latestData().values.find(v => v.key === 'temp')
      case 'sound_level_sensor':
        return this.latestData().values.find(v => v.key === 'leq_a')
      default:
        return null
    }
  }

  valueLabels(){
    let labels = [{value: this.name, unit: ''}]
    const data = this.primaryData()
    const time = this.latestData() && this.latestData().time
    // Do not show data that is more that 5 days old
    if (data && (Date.now() - time) < 5 * 24 * 60 * 60000) {
      labels.push({value: data.value.toFixed(1), unit: data.unit})
    }
    return labels
  }

  latestData(){
    if (this.data.length === 0)
      return null
    else
      return this.data[0]
  }

  loadData(){
    this.data = []
    const url = `https://api.sensormonitor.fi/api/v1/instruments/${this.id}/data?page[size]=1`
    return fetch(url, jsonConfig).then(response => response.json().then(data => ({data, response}))).then(
      ({data, response}) => {
        if (!response.ok) {
          return Promise.reject(response)
        } else {
          data.data.map(d => {
            const values = data.meta.data_attributes.map((da, index) => ({
              key: da.key,
              value: d.attributes.values[index],
              label: da.key,
              unit: da.unit
            }))
            this.data.push(Object.assign(d.attributes, {time: Time.parseUTC(d.id), values: values}))
          })
        }
      }).catch(err => {
      console.log(err)
    })
  }

  static findAll(params = {}){
    const query = Object.keys(params).map(key => `${key}=${encodeURIComponent(params[key])}`).join('&')

    // Custom data link provided by the api for instruments
    const url = 'https://api.sensormonitor.fi/api/v1/public_instruments?' + query

    return fetch(url, jsonConfig).then(response => response.json().then(data => ({data, response}))).then(
      ({data, response}) => {
        if (!response.ok) {
          return Promise.reject(response)
        } else {
          return data.data.map(obj =>
            new Instrument(obj)
          )
        }
      }).catch(err => {
      console.log(err)
    })
  }

  startPollingForData(callback){
    this.loadData().then(() => callback())
    //this.handle = window.setTimeout(() => this.startPollingForData(callback), 600000 + Math.random() * 60000)
    this.handle = window.setTimeout(() => this.startPollingForData(callback), 5000)
  }

  stopPollingForData(){
    if (this.handle)
      window.clearTimeout(this.handle)
  }
}

export default Instrument