import { useState, useEffect } from 'react'

const getWindowDimensions = () => ({windowHeight: window.innerHeight, windowWidth: window.innerWidth})

export default () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  let timeOut = null

  const handleResize = () => {
    if (timeOut)
      window.clearTimeout(timeOut)
    timeOut = window.setTimeout(() => {setWindowDimensions(getWindowDimensions())}, 250)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}