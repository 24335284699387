import React from "react"
import logo from "../images/sensor_monitor_logo.svg"
import useWindowDimensions from "../lib/useWindowDimensions"

export default class SensorMonitor {
  static init() {
    import('../styles/themes/sensor-monitor.css').then((_condition) => {});
  }

  static headerHeight() {
    const {windowWidth, windowHeight} = useWindowDimensions()
    return Math.min(windowWidth, windowHeight) < 512 ? 70 : 90
  }

  static Header() {
    return (
      <header className="app-header" style={{height: SensorMonitor.headerHeight()}}>
        <a className="logo" href="https://www.sensormonitor.fi" title="Mene sensormonitor.fi">
          <img alt="SensorMonitor" className="app-logo" width={SensorMonitor.headerHeight() * 0.6} src={logo}/>
        </a>
        <span className="app-header-title">
          SensorMonitor
        </span>
      </header>
    )
  }

  static Footer() {
    return (
      <div className="app-footer">
        © 2021 SensorMonitor
      </div>
    )
  }
}