import React, {useEffect, useState, useRef, useContext} from 'react'
import WorldMap from "../lib/WorldMap"
import Instrument from "../resources/Instrument"
import Time from '../lib/Time'
import DataModal from "./DataModal"
import {ThemeContext} from "../contexts/ThemeContext"
import useWindowDimensions from "../lib/useWindowDimensions"
import Url from "../lib/Url"

let intervalHandle = null

export default () => {
  const [ fitBounds, setFitBounds ] = useState(true)
  const [ instruments, _setInstruments ] = useState([])
  const instrumentsRef = useRef(instruments)
  const [ dataTime, setDataTime ] = useState(null)
  const [ showModal, setShowModal ] = useState(false)
  const [ currentInstrument, setCurrentInstrument ] = useState(null)
  const { headerHeight } = useContext(ThemeContext)
  const {windowHeight} = useWindowDimensions()

  const setInstruments = (val) => {
    instrumentsRef.current = val
    _setInstruments(val)
  }

  useEffect(
    () => {
      //setFitBounds(true)
      loadInstruments()
      intervalHandle = window.setInterval(() => loadInstruments(), 1000 * 60 * 2)
      return () => {
        //instrumentsRef.current.forEach(i => i.stopPollingForData())
        if (intervalHandle)
          clearInterval(intervalHandle)
      }
    },
    []
  )

  const resolveFilter = () => {
    const organizations = Url.getParam('organization')
    const tags = Url.getParam('tag')
    let filter = {}
    if (organizations)
      filter['filter[organization.name]'] = organizations
    if (tags)
      filter['filter[tag.name]'] = tags
    if (Object.keys(filter).length === 0 && process.env.REACT_APP_DEFAULT_FILTER) {
      return JSON.parse(process.env.REACT_APP_DEFAULT_FILTER)
    }
    return filter
  }

  const loadInstruments = () => {
    const filter = resolveFilter()
    if (!filter || Object.keys(filter).length === 0) {
      console.error("Filter is needed to show public instruments")
      return
    }
    Instrument.findAll(filter).then(result => {
      //result.forEach(i => i.startPollingForData(() => setDataTime(Date.now())))
      Promise.all(result.map(i => i.loadData())).then(() => {
        setDataTime(Date.now())
        window.setTimeout(() => setFitBounds(false), 4000)
      })
      setInstruments(result)
    })
  }

  const labelMargin = (index, length) => {
    const angles = {
      0: Math.PI * 1.5 - 0 * Math.PI * 0.333,
      1: Math.PI * 1.5 - 1.25 * Math.PI * 0.333,
      2: Math.PI * 1.5 - 1.5 * Math.PI * 0.333,
      3: Math.PI * 1.5 - 2.0 * Math.PI * 0.333,
      4: Math.PI * 1.5 - 3 * Math.PI * 0.333,
    }
    return {
      marginTop: `${Math.sin(angles[index % 5]) * -38 - 33}px`,
      marginLeft: `${Math.cos(angles[index % 5]) * -20 + 3 - length * 0.5}px`
    }
  }

  const showInstrument = (instrument) => {
    setCurrentInstrument(instrument)
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
    setCurrentInstrument(null)
  }

  const resolveMarker = (instrument) => {
    const latestData = instrument.latestData()
    let marker = {
      onClick: () => showInstrument(instrument),
      lat: parseFloat(latestData.lat),
      lng: parseFloat(latestData.lon),
      animation: 'DROP',
      icon: instrument.icon(),
      labels: instrument.valueLabels().map((label, index) =>
        <div key={index}
             className={"markerLabel markerLabel-" + index}
             style={{...labelMargin(index, (index === 0 ? label.value.length * 6 : 0)), color: instrument.color()}}>
          <span className="labelValue">{label.value}</span>
          <span className="labelUnit">{label.unit}</span>
        </div>)
    }
    marker.title = [instrument.name, `Measured at: ${Time.formatTime(latestData.time)}`].join("\n")
    return marker
  }

  const markers = () => {
    if (!instruments)
      return []
    let markersArray = []
    instruments.forEach(instrument => {
      if (instrument.latestData() &&
        instrument.latestData().lon !== undefined &&
        instrument.latestData().lon !== null){
        markersArray.push(resolveMarker(instrument))
      }
    })
    return markersArray
  }

  return (
    <div>
      <WorldMap
        markers={markers()}
        mapContainerStyle={{height: Math.max(windowHeight - headerHeight() - 30, 400)}}
        fitBounds={fitBounds}
      />
      <DataModal show={showModal} onClose={closeModal} instrument={currentInstrument}/>
    </div>
  )
}