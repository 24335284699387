import React, {useContext, useEffect} from 'react'
import Joensuu from "../themes/Joensuu"
import SensorMonitor from "../themes/SensorMonitor"

export const ThemeContext = React.createContext()

export default ({ children }) => {

  const resolveTheme = () => {
    const theme = process.env.REACT_APP_THEME
    if (theme === 'joensuu')
      return Joensuu
    return SensorMonitor
  }

  useEffect(() => {
    resolveTheme().init()
  }, [])

  const defaultContext = {
    Header: resolveTheme().Header,
    Footer: resolveTheme().Footer,
    headerHeight: resolveTheme().headerHeight
  }

  return (
    <ThemeContext.Provider value={defaultContext}>
      {children}
    </ThemeContext.Provider>
  )
}