import {ResponsiveContainer, ComposedChart, XAxis, YAxis, CartesianGrid, Area, Tooltip, Line} from "recharts"
import React from "react"
import Time from '../lib/Time'
import moment from 'moment-timezone'
import styles from '../styles/InstrumentDataChart.module.css'
import InstrumentDatum from "../resources/InstrumentDatum"

export default ({data, width, height, resolution, span, date, unitPostfix, instrumentType}) => {
  const formatDate = (utcDate) => {
    //return new Date(utcDate).toUTCString();
    if (resolution === 'hour')
      return Time.formatTime(utcDate, 'time', 'minutes')
    else if (resolution === 'day')
      return Time.formatTime(utcDate, 'monthAndDay')
  }

  const ticks = () => {
    let time = moment(date)
    if (resolution === 'hour'){
      time = time.endOf('hour').add(1, 'hour').add(1, 'minute')
      return [...Array(span + 1).keys()].map(i => time.subtract(1, 'hours').valueOf()).reverse()
    } else if (resolution === 'day'){
      //time = time.endOf('day').add(1, 'day')
      time = time.startOf('day').add(2, 'days')
      return [...Array(span + 1).keys()].map(i => time.subtract(1, 'days').valueOf()).reverse()
    }
    return []
  }

  const dataKeys = () => {
    if (data.length > 0 && data[0].values)
      return data[0].values.map(v => v.key)
    else
      return []
  }

  const CustomTooltip = ({active, payload, label}) => {
    if (!active)
      return null
    return (
      <div className={styles.tooltip}>
        <span className={styles.tooltipTitle}><Time>{label}</Time></span>
        <table>
          <tbody>
          {payload && payload.map(p => {
            const key = p.name
            const values = p.payload.values
            const obj = values.find(v => v.key === key)
            return(
              <tr key={p.name} className={styles.tooltipItem} style={{color: p.stroke}}>
                <td>
                  <span className={styles.tooltipItemLabel}>{obj.label}:</span>
                </td>
                <td>
                  <span className={styles.tooltipItemValue}>{obj.value}</span>
                  <span className={styles.tooltipItemUnit}>{obj.unit}{unitPostfix}</span>
                </td>
              </tr>
            )
          })}
          </tbody>
        </table>
      </div>
    )
  }

  const color = (key = 0) => {
    return [
      "#f05a73",
      "#2089dc",
      "#4db930",
      "#ae8c00",
      "#8d4b9c",
      "#dc6a1f",
      "#4a94a3",
      "#dc376b"
    ][key]
  }

  return (
    <React.Fragment>
      <ResponsiveContainer width={width || '100%'} height={height || '100%'} className={styles.container}>
        <ComposedChart baseValue="dataMin" data={data} margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id="grad1" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={color()} stopOpacity={0.7}/>
              <stop offset="95%" stopColor={color()} stopOpacity={0}/>
            </linearGradient>
          </defs>
          <Tooltip content={<CustomTooltip/>}></Tooltip>
          <XAxis dataKey="time"
                 allowDataOverflow={true}
                 tickLine={false}
                 minTickGap={2}
                 tick={{fontSize: "0.7rem"}}
                 name="Time"
                 tickFormatter={formatDate}
                 scale="utc"
                 domain={[ticks()[0], ticks().splice(-1,1)[0]]}
                 ticks={ticks()}
                 type="number"/>
          <YAxis name="Value"
                 domain={[0, 40]}
                 width={50}
                 tick={{fontSize: "0.7rem"}}/>
          <CartesianGrid strokeDasharray="3 3"/>
          {dataKeys().length === 1 &&
          <Area type="monotone"
                name={dataKeys()[0]}
                dataKey={d => d.values.filter(v => v.key === dataKeys()[0])[0].value}
                stroke={color()}
                fillOpacity={1}
                fill="url(#grad1)"/>}
          {dataKeys().length > 1 && dataKeys().map((k, i) =>
          <Line type="monotone"
                key={k}
                name={k}
                dataKey={d => d.values.filter(v => v.key === k)[0].value}
                stroke={color(i)}
                dot={false}/>)}
        </ComposedChart>
      </ResponsiveContainer>
    </React.Fragment>
  )
}