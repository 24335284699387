import React from 'react'
import Time from '../lib/Time'
import moment from "moment-timezone"

class InstrumentDatum{

  constructor(instrument){
    this.instrument = instrument
  }

  fetchData(params = ''){
    const config = {
      method: 'GET',
      headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json'
      }
    }

    // Custom data link provided by the api for instruments
    const url = `https://api.sensormonitor.fi/api/v1/instruments/${this.instrument.id}/data/?${params}`

    return fetch(url, config).then(response => response.json().then(data => ({data, response}))).then(
      ({data, response}) => {
        if (!response.ok) {
          return Promise.reject(response)
        } else {
          return data.data.map(d => {
            const values = data.meta.data_attributes.map((da, index) => ({
              key: da.key,
              value: d.attributes.values[index],
              label: InstrumentDatum.resolveLabel(da.key),
              unit: da.unit
            }))
            return Object.assign(d.attributes, {time: Time.parseUTC(d.id), values: values})
          })
        }
      }).catch(err => {
      console.log(err)
    })
  }

  async dataForChart(params, time){
    if (!time)
      return []
    let datum
    time = moment(time)
    if (params.resolution === 'hour'){
      let to = time.endOf('hour').toISOString()
      let from = time.endOf('hour').subtract(params.span, 'hours').toISOString()
      return this.fetchData(`filter[from]=${from}&filter[to]=${to}&page[size]=1500`)
    } else if (params.resolution === 'day'){
      let to = time.endOf('day').toISOString()
      let from = time.endOf('day').subtract(params.span, 'days').add(1, 'second')
      let data = []
      /*
      if (params.span >= 30) {
        for(let i = 0; i <= params.span; i ++){
          datum = await this.avg(from, 'day')
          if (datum)
            data.push(datum)
          from.add(1, 'day')
        }
        return data.reverse()
      } else if (params.span >= 7) {
        for(let i = 0; i <= params.span * 8; i ++){
          datum = await this.avg(from, 'hour', 3)
          if (datum)
            data.push(datum)
          from.add(3, 'hour')
        }
        return data.reverse()
      } else {*/
        from = from.toISOString()
        return this.fetchData(`filter[from]=${from}&filter[to]=${to}&page[size]=1500`)
      //}
    }
  }

  // This is done client side mainly because of timezones
  avg(date, resolution, span = 1){
    const from = moment(date).toISOString()
    const to = moment(date).add(span, resolution).toISOString()
    let sums = {}
    let counts = {}
    let units = {}
    let avgs = []
    return this.fetchData(`filter[from]=${from}&filter[to]=${to}&page[size]=1500`).then(result => {
      if (result && result.length > 0) {
        for (let r of result) {
          if (!r.values)
            continue
          r.values.forEach(v => {
            units[v.key] = v.unit
            if (!sums[v.key])
              sums[v.key] = 0
            if (!counts[v.key])
              counts[v.key] = 0
            sums[v.key] += parseFloat(v.value)
            counts[v.key]++
          })
        }
        Object.keys(sums).forEach(k => avgs.push({
          key: k,
          value: Math.round(sums[k] / counts[k]),
          label: InstrumentDatum.resolveLabel(k),
          unit: units[k]
        }))
        //const diff = moment(date).startOf(resolution).add(span * 0.5, resolution).diff(moment(date).startOf(resolution))
        //const time = moment(date).startOf(resolution).valueOf() + diff
        const time = moment(date).valueOf()
        return {time: time, values: avgs, lon: result[0].lon, lat: result[0].lat}
      } else
        return null
    })
  }

  static chartOptions(attribute, instrumentType){
    let ret
    switch(attribute) {
      case 'pressure':
        if (instrumentType === 'pressure_sensor')
          ret = {yAxisDomain: [dataMin => Math.min(dataMin, -20), dataMax => Math.max(dataMax, 20)]}
        else if (instrumentType === 'pressure_abs_sensor')
          ret = {yAxisDomain: [dataMin => Math.min(dataMin, 0), dataMax => Math.max(dataMax, 2000)]}
        break
      case 'snow_depth':
        ret = {yAxisDomain: [0, dataMax => Math.max(dataMax, 150)]}
        break
      case 'motion':
        ret = {yAxisDomain: [0, dataMax => Math.max(dataMax, 25)]}
        break
      case 'co2':
        ret = {yAxisDomain: [0, dataMax => Math.max(dataMax, 1000)]}
        break
      case 'temp':
        ret = {yAxisDomain: [dataMin => Math.min(dataMin, -40), dataMax => Math.max(dataMax, 40)]}
        break
      case 'slow_a':
      case 'fast_a':
      case 'leq_a':
      case 'slow_c':
      case 'fast_c':
      case 'leq_c':
        ret = {yAxisDomain: [0, dataMax => Math.max(dataMax, 150)]}
        break
      default:
        ret = {yAxisDomain: [0, dataMax => Math.max(dataMax, 100)]}
    }
    return ret
  }

  static resolveLabel(key){
    if (key === 'slow_a')
      return 'Slow A'
    else if (key === 'slow_c')
      return 'Slow C'
    else if (key === 'fast_a')
      return 'Fast A'
    else if (key === 'fast_c')
      return 'Fast C'
    else if (key === 'leq_a')
      return <span>L<sub>eq</sub> A</span>
    else if (key === 'leq_c')
      return <span>L<sub>eq</sub> C</span>
    else if (key === 'co2')
      return <span>CO<sub>2</sub></span>
    return key.charAt(0).toUpperCase() + key.replace('_', ' ').slice(1)
  }
}

export default InstrumentDatum